

import React from 'react'
import { appLinksList, appLinksListMobile, socialsList } from './constants'
import Image from '../atom/image'

const AppsAndSocials = () => {
  return (
    <div className="tw-flex max-md:tw-mt-9 md:tw-flex-col-reverse">
      {/* <div className="md:tw-mt-14 max-md:tw-w-1/2 ">
        <h6 className="tw-text-base tw-leading-6 md:tw-leading-5 tw-font-semibold tw-m-0 tw-text-titleWhite">Our Partner App</h6>
        <div className="tw-hidden md:tw-flex tw-gap-3 tw-mt-3">
          {appLinksList.map((item, i) =>
            <a key={i} href={item.link} title={item.title} target="_blank" rel="noopener noreferrer">
              <img
                src={item.image}
                alt={item.title}
                width={item.width}
                className="tw-h-10"
              />
            </a>
          )}
        </div>
        <div className="tw-flex md:tw-hidden tw-gap-3 tw-mt-3">
          {appLinksListMobile.map((item, i) =>
            <a key={i} href={item.link} title={item.title} target="_blank" rel="noopener noreferrer">
              <img
                src={item.image}
                alt={item.title}
                className="tw-h-6 tw-w-6"
              />
            </a>
          )}
        </div>
      </div> */}

      <div className="md:tw-mt-14 max-md:tw-w-1/2 ">
        <h6 className="tw-text-base tw-leading-6 md:tw-leading-5 tw-font-semibold tw-m-0 tw-text-titleWhite">Follow Us On</h6>
        <div className="tw-flex tw-gap-2 md:tw-gap-5 tw-mt-3">
          {socialsList.map((item, i) =>
            <a key={i} href={item.link} title={item.title} target="_blank" rel="noopener noreferrer">
              <img
                src={item.image}
                alt={item.title}
                className="tw-block tw-h-6 tw-w-6 md:tw-opacity-100 hover:md:tw-opacity-80"
              />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default AppsAndSocials