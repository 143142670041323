const ourFundsList = [
  {
    title: "Indian Equity",
    link: `/mutual-funds/category/indian-equity`,
    image: "/assets/images/header/indian_equity.svg",
    isFundCategory: true,
  },
  {
    title: "International Equity",
    link: `/mutual-funds/category/international-equity`,
    image: "/assets/images/header/international_equity.svg",
    isFundCategory: true,
  },
  {
    title: "Hybrid & Balanced",
    link: `/mutual-funds/category/hybrid-balanced`,
    image: "/assets/images/header/hybrid_and_balanced.svg",
    isFundCategory: true,
  },
  {
    title: "Multi Asset",
    link: `/mutual-funds/category/multi-asset`,
    image: "/assets/images/header/multi_asset.svg",
    isFundCategory: true,
  },
  {
    title: "Commodity",
    link: `/mutual-funds/category/commodity`,
    image: "/assets/images/header/commodity.svg",
    isFundCategory: true,
  },
  {
    title: "Debt & Liquid",
    link: `/mutual-funds/category/debt-liquid`,
    image: "/assets/images/header/debt_and_liquid.svg",
    isFundCategory: true,
  },
]

const fundTypes = [
  {
    id: "Index",
    title: "Index Funds",
    link: `/index-fund`,
    image: "/assets/images/header/index_fund.svg",
  },
  {
    id: "ETFs",
    title: "ETFs",
    link: `/etf`,
    image: "/assets/images/header/etf.svg",
  }
]

const investorEducationList = [
  {
    title: "Blogs",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}investor-education/category/blog/`
  },
  {
    title: "Videos",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}investor-education/category/videos/`
  },
  {
    title: "5 Keys of Investing",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}investor-education/category/5-keys-of-investing/`
  },
  {
    title: "Wealth Creation Thoughts",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}investor-education/wealth-creation-thoughts`
  }
]

const toolsAndCalculatorsList = [
  {
    title: "SIP Returns Calculator",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}sip-calculator`
  },
  {
    title: "Compare Portfolio",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}tools/compare-portfolio`
  },
  {
    title: "Compounding Magic",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}tools/compounding-magic`
  },
]

const newsAndInsights = [
  {
    title: "Blogs",
    link: "https://www.motilaloswalamc.com/insights/"
  },
  {
    title: "Press-Release",
    link: `/Media-Room/Press-Release`
  },
  {
    title: "Interviews",
    link: `/Media-Room/Press-Release`
  },
  {
    title: "News",
    link: `/Media-Room/Press-Release`
  }
]

const importantLinks = [
  {
    title: "Downloads",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}download/forms`
  },
  {
    title: "NAV & TER",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}nav/latest-nav`,
  },
  {
    title: "Investor Charter",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}assets/pdf/Investor_Charter.pdf`,
    external: true
  }, {
    title: "Account Statement",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}reporthub`,
  },
  {
    title: "Contact Us",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}contact-us`,
  }
]

const mutualFundsList = [
  {
    title: "Become a Distributor",
    link: `https://online.motilaloswalmf.com/Empanelment/`
  },
  {
    title: "Downloads",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}download/forms`
  },
  {
    title: "FAQ",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}faq/`
  },
]

const pmsList = [
  {
    title : "Onboard New Client",
    link : "https://digipms.motilaloswalamc.com/login"
  },
  {
    title: "Downloads",
    link: `${process.env.REACT_APP_MOTILAL_PMS_MODULE_URL}download/document-disclosure`
  },
  {
    title: "FAQ",
    link: `${process.env.REACT_APP_MOTILAL_PMS_MODULE_URL}faq`
  },
]

const aifList = [
  {
    title : "Onboard New Client",
    link : `https://digiaif.motilaloswalamc.com/login`
  },
  {
    title: "Downloads",
    link: `${process.env.REACT_APP_MOTILAL_AIF_MODULE_URL}download/financials`
  },
]

const distributorServices = [
  {
    title: "MUTUAL FUND",
    isHeading : true
  },
  ...mutualFundsList,
  {
    title: "PMS",
    isHeading: true
  },
  ...pmsList,
  {
    title : "AIF",
    isHeading : true
  },
  ...aifList ,
  {
    title: "Dist Important Links",
    isHeading: true
  },
]

const distImportantLinks = [
  {
    title: "Login",
    link: "https://partners.moamc.com/investonline/dist"
  },
  {
    title: "Quick Purchase - MF",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}url-builder/purchaselink.html`,
  },
  {
    title: "Account Statement",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}reporthub`,
  },
]

const mobileHeaderLinks = {
  // "Our Funds": [
  //   {
  //     title: "Fund Categories",
  //     isHeading: true
  //   },
  //   ...ourFundsList,
  //   {
  //     title: "Fund Types", // Using just as a flag
  //     isHeading: true
  //   },
  //   {
  //     title: "Popular Funds", // Using just as a flag
  //     isHeading: true
  //   },
  // ],
  "Investor Services": [
    {
      title: "Investor Education",
      isHeading: true
    },
    ...investorEducationList,
    {
      title: "Tools & Calculators",
      isHeading: true
    },
    ...toolsAndCalculatorsList,
    {
      title: "News & Insights",
      isHeading: true
    },
    ...newsAndInsights,
    {
      title: "Important Links",
      isHeading: true
    },
  ],

  "Partner Services": [
    {
      title: "MUTUAL FUND",
      isHeading : true
    },
    ...mutualFundsList,
    {
      title: "PMS",
      isHeading: true
    },
    ...pmsList,
    {
      title : "AIF",
      isHeading : true
    },
    ...aifList ,
    {
      title : "Dist Important Links",
      isHeading : true
    }
  ],
}



export {
  ourFundsList,
  fundTypes,
  investorEducationList,
  toolsAndCalculatorsList,
  newsAndInsights,
  importantLinks,
  distributorServices,
  mobileHeaderLinks,
  mutualFundsList,
  pmsList,
  aifList,
  distImportantLinks
}
