import { distributorServices, mutualFundsList, pmsList, aifList, distImportantLinks } from "./constants"

// const DistributorServicesPopup = () => {
//   return (
//     <div className="menu-container tw-fixed tw-left-0 tw-top-[var(--top-for-menu-popup)] tw-w-full tw-z-[1000]">
//       <div className='menu-popup container tw-flex tw-overflow-hidden tw-p-0'>
//         <div className='tw-py-8 tw-px-[100px] tw-w-full'>
//           <div className="tw-grid tw-grid-cols-3 tw-w-full tw-gap-y-4 tw-gap-x-12">
//             {distributorServices.map((item, i) =>
//               <div key={i} className="tw-flex tw-items-center tw-gap-4 tw-py-4">
//                 <img src={item.image} alt={item.title} width={32} height={32} />
//                 <a key={i} href={item.link} title={item.title} className="tw-text-base tw-leading-6 tw-w-fit tw-font-semibold hover:tw-underline">{item.title}</a>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }


const DistributorServicesPopup = () => {
  return (
    <div className="menu-container tw-fixed tw-left-0 tw-top-[var(--top-for-menu-popup)] tw-w-full tw-z-[1000]">
      <div className='menu-popup container tw-flex tw-overflow-hidden tw-p-0'>
        <div className="tw-grid tw-grid-cols-3 tw-w-3/4">
          <div className='tw-p-8'>
            <h4 className="tw-w-3/5 2xl:tw-w-2/5 tw-uppercase tw-text-base tw-leading-5 tw-font-semibold tw-m-0 tw-pb-5">MUTUAL FUND</h4>

            <div className="tw-pt-5 tw-grid tw-gap-y-5 tw-border-t tw-border-greyBorder">
              {mutualFundsList.map((item, i) =>
                <a key={i} href={item.link} title={item.title} className="tw-text-base tw-leading-5 tw-w-fit hover:tw-underline">{item.title}</a>
              )}
            </div>
          </div>
          <div className='tw-py-8 tw-pl-8 tw-pr-12'>
            <h4 className="tw-w-3/5 2xl:tw-w-2/5 tw-uppercase tw-text-base tw-leading-5 tw-font-semibold tw-m-0 tw-pb-5">PMS</h4>

            <div className="tw-pt-5 tw-grid tw-gap-y-5 tw-border-t tw-border-greyBorder">
              {pmsList.map((item, i) =>
                <a key={i} href={item.link} title={item.title} className="tw-text-base tw-leading-5 tw-w-fit hover:tw-underline">{item.title}</a>
              )}
            </div>
          </div>

          <div className='tw-py-8 tw-pl-8 tw-pr-12'>
            <h4 className="tw-w-3/5 2xl:tw-w-2/5 tw-uppercase tw-text-base tw-leading-5 tw-font-semibold tw-m-0 tw-pb-5">AIF </h4>

            <div className="tw-pt-5 tw-grid tw-gap-y-5 tw-border-t tw-border-greyBorder">
              {aifList.map((item, i) =>
                <a key={i} href={item.link} title={item.title} className="tw-text-base tw-leading-5 tw-w-fit hover:tw-underline">{item.title}</a>
              )}
            </div>
          </div>

        </div>
        <div className="tw-w-1/4 tw-h-full tw-py-7 tw-pr-8 tw-pl-12 tw-bg-[#EEF5FD]">
          {/* <h4 className="tw-w-3/5 2xl:tw-w-2/5 tw-uppercase tw-text-base tw-leading-5 tw-font-semibold tw-m-0 tw-pb-5">Important Links</h4> */}
          <h4 className="tw-pt-7 tw-uppercase tw-text-base tw-leading-5 tw-font-semibold tw-m-0 tw-pb-5 tw-whitespace-nowrap tw-w-full">
  Important Links
</h4>

          <div className="tw-pt-5 tw-grid tw-grid-cols-1 tw-gap-y-5 tw-border-t tw-border-greyBorder">
            {distImportantLinks.map((item, i) =>
              <div key={i} className="tw-flex tw-items-start tw-gap-4">
                <a href={item.link} title={item.title} className="tw-text-base tw-leading-5 hover:tw-underline" target={item.external ? "_blank" : "_self"}>{item.title}</a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DistributorServicesPopup