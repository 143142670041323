import Image from "../atom/image";
import './whatsappPromoHeader.css'
import { useMediaQuery } from '@material-ui/core'

const WhatsappPromoHeader = () => {
  const isMobile = useMediaQuery('(max-width:990px)')
  return (
    <div className='whatsappHeaderPromo'>
      <div className='whatsappWrapper'>
        {
          isMobile
            ? <Image image="whatsapp_banner_headline_mobile_new.gif" alt="Message gif" class='animated-text' height={104} width={820} />
            : <Image image="whatsapp_banner_headline_desktop_new.gif" alt="Message gif" class='animated-text' height={104} width={820} />
        }
        <a href="https://wa.me/7304921822" className='whatsapp-cta'>
          <Image image="whatsapp_blinking_icon_new.gif" alt="Whatsapp icon gif" height={40} width={40} />
          <span>73049 21822</span>
        </a>
      </div>
    </div>
  )
}

export default WhatsappPromoHeader