
const Branding = () => {
  return (
    <div>
      <a href="/" title="Motilal Oswal Asset Management Company" className="tw-relative tw-block tw-h-[80px] tw-w-[137px]">
        <img
          src={"/assets/images/footer/mo_mf_logo_white.svg"}
          alt="Motilal Oswal Asset Management Company"
          fill
          className="tw-object-contain tw-h-full tw-w-full"
        />
      </a>
      <h6 className="tw-text-base tw-leading-6 md:tw-leading-5 tw-font-semibold tw-m-0 tw-mt-3 md:tw-mt-4 tw-text-titleWhite">Motilal Oswal Asset Management Company</h6>
    </div>
  )
}

export default Branding