import React, { useState, useEffect, useRef } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { logout } from "../../redux/actions/login";
import Navbar from "./shared/navbar";
import Sidebar from "./shared/sidebar";
import Footer from "./shared/footer";
import { useSelector, useDispatch } from "react-redux";
import "../../components/pages/dashboard/dashboard.css";
import "./shared/shared.css";
import { osName } from "react-device-detect";
import loginApi from "../../services/loginApi";
// import userApi from '../../services/userApi';
import { fetchWithWait, getDecryptedItem } from "../../helper/methods";
import { menuRoleWise } from "../../redux/actions/initApp";
import Cookie from '../../lib/cookie';
import { endpoints } from '../endpoints';
import Popup from "reactjs-popup";
import './sessionExpiryPopup.css';
import globalVariable from "../globalVariable";
import { removeCookie } from "../../redux/actions/removeCookies";
import { useHistory } from "react-router-dom";
import { authDetails } from "../../redux/actions/trans/authAction";
import { useQueryClient } from 'react-query';
import { loginTypeCheck } from '../.../../../redux/actions/trans/authAction';

let api = new loginApi();
// let usApi = new userApi();
let cookie = new Cookie();

const PrivateRoute = ({ component: Component, restricted, ...rest }, props) => {
    let dispatch = useDispatch();
    const history = useHistory();
    const [selectedProduct, setselectedProduct] = useState("");
    const queryClient = useQueryClient();
    let productDtlsFromLS = getDecryptedItem('productDtls');

    useEffect(() => {
        if (productDtlsFromLS) {
            let token = productDtlsFromLS?.atk?.accessToken
            let refreshToken = productDtlsFromLS?.atk?.refreshToken
            if (token && refreshToken) {
                cookie.setCookiewithSecureFlags('token', token, { path: '/', sameSite: 'strict' });
                cookie.setCookiewithSecureFlags('refreshToken', refreshToken, { path: '/', sameSite: 'strict' });
            }
        }

    },[productDtlsFromLS])

    // let menuList = useSelector(state => state.initApp.menuRoleWise || []);
    let getArn = useSelector(state => state.Auth.arnDetails);

  const urlParams = new URLSearchParams(window.location.search);
  const currentURL = window.location.pathname; // Get the current pathname
  let redirectDitUrl = urlParams.get("redirectDitUrl");
  // let authData = urlParams.get("authData");
  // let parsedAuthData = JSON.parse(authData);

  // useEffect(() => {
  //   if (parsedAuthData && parsedAuthData !== '' && parsedAuthData !== null) {
  //     console.log("checking at line 33: ",parsedAuthData);
  //     dispatch(authDetails(parsedAuthData));
  //   }
  // },[parsedAuthData])

  useEffect(() => {
    // const urlParams = new URLSearchParams(window.location.search);
    const authData = urlParams.get("authData");
    const parsedAuthData = authData ? JSON.parse(authData) : null;
    const arnCode = localStorage.getItem('ARNCode')
  
    if (parsedAuthData && parsedAuthData !== '' && parsedAuthData !== null) {
      console.log("checking at line 33: ", parsedAuthData);
      dispatch(authDetails(parsedAuthData));
    }

    let isArn = false;
    let arnCodeLowercase = arnCode?.toLowerCase();
    let arnCodeSplit = arnCodeLowercase?.split('-');
    if (arnCodeSplit?.includes('arn')) {
      isArn = false;
    } else {
      isArn = true;
    }
    dispatch(loginTypeCheck(isArn));
  }, []);

  useEffect(() => {
    let authDetailsStorage = localStorage.getItem("authDetailsForMF");
    if (authDetailsStorage) {
      try {
        let authData = JSON.parse(authDetailsStorage);
        console.log("checking at line 118: ", authData);
    
        if (authData) {
          dispatch(authDetails(authData));
        }
      } catch (error) {
        console.error("Error parsing authDetailsStorage:", error);
      }
    } else {
      console.log("authDetailsStorage is null or undefined");
    }
  }, []);
  
  if (currentURL === "/dashboard") {
    console.log('checking at line 33: ', currentURL)
    redirectDitUrl = "ALL";
  } else {
      redirectDitUrl = "MF";
  }

  console.log('checking at line 33 b: ', redirectDitUrl)

  localStorage.setItem("redirectDitUrl", redirectDitUrl);

  const [showDitMenu, setShowDitMenu] = useState(null);
  const [option, setOption] = useState([
    {
      menu: {
        label: "Business Dashboard",
        linkTo: `/business-dashboard`,
        class: "myportfolio_navicon",
      },
    },
    {
      menu: {
        label: "Create New Folio",
        linkTo: `/create-new-folio`,
        class: "newFolio_navicon",
      },
    },

    {
      menu: { label: "Investor List", linkTo: ``, class: "invest_navicon" },
      subMenu: [
        {
          menu: {
            label: "Active Investor",
            linkTo: `/investor-list/active-investor`,
            class: "xyz",
          },
        },
        {
          menu: {
            label: "Pending on-boarding",
            linkTo: `/dashboard/pendingData`,
            class: "xyz",
          },
        },
      ],
    },
    {
      menu: {
        label: "Quick Invest",
        linkTo: `/quick-invest`,
        class: "quickInvest_navicon",
      },
    },
    {
      menu: {
        label: "Bulk Transaction",
        linkTo: `/bulk-transaction`,
        class: "bulkTxn_navicon",
      },
    },
    // {
    //     menu: { label: 'Allocate in Demat', linkTo: ``, class: "aldemat_navicon" },
    //     subMenu: [
    //         { menu: { label: 'Lumpsum', linkTo: ``, class: "_ac" }, },
    //         { menu: { label: 'SIP', linkTo: ``, class: "_ac" }, },
    //     ]
    // },
    //{ menu: { label: 'Goal Based Investment', linkTo: ``, class:"goalbase_navicon" }, },
    // {
    //     menu: { label: 'Redemption', linkTo: ``, class: "redemption_navicon" },
    //     subMenu: [
    //         { menu: { label: 'Sell', linkTo: `/transcat/redeem`, class: "_ac" }, },
    //         { menu: { label: 'SWP', linkTo: `/transcat/SWP`, class: "_ac" }, },
    //     ]
    // },
    // {
    //     menu: { label: 'Transfer', linkTo: ``, class: "transfer_navicon" },
    //     subMenu: [
    //         { menu: { label: 'Switch', linkTo: `/transcat/switch`, class: "_ac" }, },
    //         { menu: { label: 'STP', linkTo: `/transcat/stp`, class: "_ac" }, },
    //     ]
    // },
    // {
    //     menu: { label: 'Redemption', linkTo: ``, class: "redemption_navicon" },
    //     subMenu: [
    //         { menu: { label: 'Sell', linkTo: `/dashboard/redeem`, class: "_ac" }, },
    //         { menu: { label: 'SWP', linkTo: `/dashboard/swp`, class: "_ac" }, },
    //     ]
    // },
    // {
    //     menu: { label: 'Transfer', linkTo: ``, class: "transfer_navicon" },
    //     subMenu: [
    //         { menu: { label: 'Switch', linkTo: `/dashboard/switch`, class: "_ac" }, },
    //         { menu: { label: 'STP', linkTo: `/dashboard/stp`, class: "_ac" }, },
    //     ]
    // },

    {
      menu: {
        label: "Systematic Transaction",
        linkTo: ``,
        class: "mnschedule_navicon",
      },
      subMenu: [
        {
          menu: {
            label: "SIP",
            linkTo: "/manage-investments-sip",
            class: "_ac",
          },
        },

        {
          menu: {
            label: "SWP",
            linkTo: "/manage-investments-swp",
            class: "_ac",
          },
        },
        {
          menu: {
            label: "STP",
            linkTo: "/manage-investments-stp",
            class: "_ac",
          },
        },
      ],
    },
    {
      menu: { label: "Reports", linkTo: ``, class: "report_navicon" },
      subMenu: [
        {
          menu: {
            label: "Account Statement",
            linkTo: "/reports/account-statement",
            class: "_ac",
          },
        },
        // {
        //     menu: {
        //         label: 'Capital Gain Loss', linkTo: '/reports/capital-gain-loss', class: "_ac"
        //     }
        // },
        // {
        //     menu: {
        //         label: 'SIP', linkTo: '/reports/sip', class: "_ac"
        //     }
        // },
      ],
    },

    {
      menu: {
        label: "Transaction History ",
        linkTo: `/initiated-transaction-history`,
        class: "orderbook_navicon",
      },
    },
    {
      menu: {
        label: "Feedback",
        url: true,
        linkTo: `https://forms.gle/ky41ZQ6mCc9HfLEn7`,
        class: "feedback_navicon",
      },
    },
    {
      menu: {
        label: "Manual",
        url: true,
        target: "_blank",
        linkTo: `${endpoints.manual_URL}`,
        class: "manual_navicon",
      },
    },
    {
      menu: {
        label: "Contact Us",
        linkTo: `/feedback`,
        class: "feedback_navicon",
      },
    },

    // { menu: { label: 'Initiated History', linkTo: `/dashboard/InitiatedHistory`, class: "orderbook_navicon" }, },

    // { menu: { label: 'DIT Reports', linkTo: `/dashboard/ditReports`, class: "report_navicon" }, },
  ]);

  useEffect(() => {
    let paylaod = {};
    if (cookie.getCookie("userRollID")) {
      console.log(`object cookie`, cookie.getCookie("userRollID"));
      paylaod = { roleId: cookie.getCookie("userRollID") };
    } else {
      paylaod = { roleId: "1" };
    }

    ditMenu();

    // fetchWithWait({ dispatch, action: menuRoleWise(paylaod) }).then((res) => {
    //     console.log(`res`, res)
    //     setOption(res)

    // }).catch((e) => {
    //     console.log(`error`, e)
    // })
  }, []);

  const [showPopup, setshowPopup] = useState(false);
    const singleTimeRef = useRef(false);

    useEffect(() => {
        // Subscribe to the 'httpError' event
        console.log("rest.location.pathname", rest.location.pathname)
        // if (rest.location?.pathname != "/onboarding/personal") {
        const onHttpError = (status) => {
            if (status === "failed" && !singleTimeRef.current) {
                console.log('AAAAAAAAA');
                singleTimeRef.current = true;
                setshowPopup(true);
                localStorage.setItem('sessionExpired', true);
                localStorage.setItem('triggered401', true);
            }
        };

        let events = globalVariable.getPropertyByName("events");
        events.subscribe('httpError', onHttpError);
        // }

        console.log('mounting PrivateRoute');
        return (() => {
            console.log('unmounting PrivateRoute');
            // Clean up the subscription when the component unmounts
            events.unsubscribe('httpError', onHttpError);
        })

    }, []);

  let path = rest.match.path;
  let locationPath = rest.location.pathname; // currentUrl e.g. /dashboard/systematicPlan, /dashboard/transcat/invest

  const addDit = (arr, newValue) => {
    const found = arr.some((el) => el.menu.label === newValue.menu.label);
    if (!found) arr.push(newValue);
    return arr;
  };

  if (getArn === null) {
    //  rest.history.push('/investonline/dist')
  }

  // useEffect(() => {
  //      ditMenu()
  // }, [])

  const ditMenu = async () => {
    console.log("checking at line 276: ", getArn)
    let payload = {
      apiName: "ditmenu",
      body: {
        agentCode: getArn !== null ? getArn.arnno : "", //"ARN-6631" ||
        apkVer: "1.0.0",
        //"imei": "14.25.36.25", //added in middleware
        os: osName,
      },
    };

    const ditApiResponse = await api.common(payload);
    if (ditApiResponse && ditApiResponse.data && ditApiResponse.data.success) {
      // let user_type = ditApiResponse.data.data.message.toUpperCase();
      if (ditApiResponse.data.data.isShow) {
        setShowDitMenu(true);
      }
    }
  };

  useEffect(() => {
    if (showDitMenu) {
      let dit = {
        menu: {
          label: "DIT Reports",
          linkTo: `/dit-reports`,
          class: "report_navicon",
        },
      };
      const newArray = addDit([...option], dit);
      setOption(newArray);
    }
  }, [showDitMenu]);

  const logoutClick = () => {
    queryClient.clear();
    dispatch(logout());
  };

const popupClose = (e) => {
    try {
        console.log('start');
        fetchWithWait({ dispatch, action: removeCookie() }).then(res => {
            // setshowPopup(false);
            localStorage.removeItem('sessionExpired');
            localStorage.removeItem('triggered401');
            // singleTimeRef.current = false;
            // window.location.href = '/investonline/dist';
            history.push('/investonline/dist');
        }).catch(err => {
            console.log('err in removeCookie: ', err)
            // setshowPopup(false);
            localStorage.removeItem('sessionExpired');
            localStorage.removeItem('triggered401');
            // singleTimeRef.current = false;
            // window.location.href = '/investonline/dist';
            history.push('/investonline/dist');
        })
    } catch (err) {
        console.log('catch error');
    }
}

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <React.Fragment>
      <div className="container-scroller">

        {
          showPopup ?
            <>
              <Popup open={showPopup} onClose={popupClose} className="sessionExpiredPP" modal>
                {/* <div ref={overlayRef} className="popup-overlay" onClick={handleOverlayClick}> */}
                {/* Popup content */}
                <div className="popup-contents">
                  <h2>Session Expired</h2>
                  <p>Your session has been expired!! Kindly login again.</p>
                  <button onClick={popupClose}>Login</button>
                </div>
                {/* </div> */}
              </Popup>
            </>
            :
            <div className="container-fluid page-body-wrapper">
              <Sidebar option={option} />

              <div className={`main-panel ${redirectDitUrl === 'ALL' ? 'show-full-screen' : null}`}>
                <Navbar logout={logoutClick} />
                <div className="content-wrapper">
                  <Route {...rest} render={props => (<Component {...props} {...rest} />)} />
                </div>
                <Footer />
              </div>
            </div>
        }

      </div>
    </React.Fragment>
  );
};

export default withRouter(PrivateRoute);
