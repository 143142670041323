import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default class Cookie {
    setCookie({ name, value }) {
        cookies.set(name, value);
    }
    setCookieNew(name, value, path = '/' ) {
        cookies.set(name, value, { path });
    }
    setCookiewithSecureFlags(name, value, options = {}) {
        cookies.set(name, value, { ...options, secure: true });
    }
    getCookie(name) {
        return cookies.get(name);
    }
    removeCookie(name) {
        return cookies.remove(name, { path: '/' });
    }
}
