import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        // backgroundColor: theme.palette.background.paper,
        border: '1px solid #E19C14',
        fontSize: 14,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:focus': {
            borderRadius: 4,
            borderColor: '#E19C14',
            boxShadow: '0 0 0 0.2rem #E19C14',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

const CustomizedSelects = ({ productData, handleClick, selectedProduct }) => {
    const classes = useStyles();
    const [selectedOption, setSelectedOption] = useState(selectedProduct || '');

    useEffect(() => {
        setSelectedOption(selectedProduct || '');
    }, [selectedProduct]);

    const handleChange = (event) => {
        let value = event.target.value
        setSelectedOption(value);

        const selectedCurr = productData.find(item => item.filter === value);
        
        if (selectedCurr) {
            handleClick(selectedCurr);
        }
    };
    return (
        <div>
            <FormControl className={classes.margin}>
                {/* <InputLabel htmlFor="demo-customized-select-native">Age</InputLabel> */}
                <NativeSelect
                    id="demo-customized-select-native"
                    value={selectedOption}
                    onChange={handleChange}
                    input={<BootstrapInput />}
                >
                    {productData?.map((curr, index) => (
                        <option key={index} value={curr.filter}>
                            {curr.Heading}
                        </option>
                    ))}
                </NativeSelect>
            </FormControl>
        </div>
    );
}

export default CustomizedSelects