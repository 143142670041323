
const Contact = () => {
  return (
    <div className="tw-mt-9 md:tw-mt-14">
      <h6 className="tw-text-base tw-leading-6 md:tw-leading-5 tw-m-0 tw-font-semibold tw-text-titleWhite">Contact Us</h6>
      <div className="tw-flex tw-flex-col tw-gap-5 tw-mt-3">
        <div className="tw-flex tw-items-center tw-gap-3">
          <div className='after:tw-block after:tw-w-6 after:tw-h-6 after:tw-bg-[url(/assets/images/footer/mail.png)] after:tw-bg-cover' />
          <a href={"mailto:partnerservice@motilaloswal.com"} title="partnerservice@motilaloswal.com" className="tw-text-sm tw-text-white hover:tw-text-white hover:tw-underline tw-text-footerWhite">partnerservice@motilaloswal.com</a>
        </div>
        <div className="tw-flex tw-items-center tw-gap-3">
          <div className='after:tw-block after:tw-w-6 after:tw-h-6 after:tw-bg-[url(/assets/images/footer/call.png)] after:tw-bg-cover' />
          <div className="tw-flex tw-items-center">
            <a href={"tel:+912240548002"} title="+912240548002" className="tw-text-sm tw-text-white hover:tw-text-white hover:tw-underline tw-text-footerWhite">+91-22-40548002</a>
            /
            <a href={"tel:+918108622222"} title="+918108622222" className="tw-text-sm tw-text-white hover:tw-text-white hover:tw-underline tw-text-footerWhite">8108622222</a>
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-gap-3">
          <div className='after:tw-block after:tw-w-6 after:tw-h-6 after:tw-bg-[url(/assets/images/footer/whatsapp.png)] after:tw-bg-cover' />
          <a href={"https://wa.me/7304921822"} title="+917304921822" className="tw-text-sm tw-text-white hover:tw-text-white hover:tw-underline tw-text-footerWhite">+91-7304921822</a>
        </div>
      </div>
    </div>
  )
}

export default Contact