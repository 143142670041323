/** Router component */
import React, { Component, createRef } from "react";
import loadable from "@loadable/component";
import { Switch, Route } from "react-router-dom";
import { hot } from "react-hot-loader/root";
import PublicRoute from "../services/router/pubicRoute";
import PrivateRoute from "../services/router/privateRoute";
import { registerServiceWorker } from "../helper/serviceWorkerHelper";
import { registerPushNotifications } from "../helper/notification";

const Login = loadable(() => import('./pages/login'));
const InvestOnline = loadable(() => import('./pages/login/investonline'));
const PasswordResetSuccess = loadable(() => import('./organism/investonlinenew/pwdResetSuccessPage'));
const PasswordattemptReached = loadable(() => import('./organism/investonlinenew/pwdMaxAttempt'));
const OtpattemptReached = loadable(() => import('./organism/investonlinenew/otpMaxAttempt'));
const NoMatch = loadable(() => import('./pages/nomatch'));
const Dashboard = loadable(() => import('./pages/dashboard'));
const Prelogin = loadable(() => import('./pages/preloginPages/route'));;
const About = loadable(() => import('./pages/preloginPages/aboutus'));
const FeedbackPreLogin = loadable(() => import('./pages/preLoginFeedback'));
const Contact = loadable(() => import('./pages/preloginPages/contact/contact'));

const Tools = loadable(() => import("./pages/preloginPages/tools"));
const Nav = loadable(() => import("./pages/preloginPages/nav"));
const Business = loadable(() =>
  import("./pages/preloginPages/InternationalBusiness")
);
const MFFund = loadable(() => import("./pages/preloginPages/landing/mf"));
const CategoryPage = loadable(() => import("./pages/preloginPages/category"));
const CategoryNew = loadable(() =>
  import("./pages/preloginPages/category/category")
);
const MFDetail = loadable(() =>
  import("./pages/preloginPages/listingPage/mflisting")
);
const Compare = loadable(() =>
  import("./pages/preloginPages/compare/comparefund")
);
const Faq = loadable(() => import("./pages/preloginPages/faq/faq"));
const PassiveFund = loadable(() =>
  import("./pages/preloginPages/landing/passivefund")
);
const AccountStmt = loadable(() =>
  import("./pages/preloginPages/accountStatement")
);
const PMSDetail = loadable(() =>
  import("./pages/preloginPages/listingPage/pmsDetail")
);
const PMSBusiness = loadable(() =>
  import("./organism/listingPage/PMSbusinessportfolio")
);
const PMSNext = loadable(() =>
  import("./organism/listingPage/PMSNesttrillion")
);
const Indiastrategy = loadable(() =>
  import("./organism/listingPage/indiastrategy")
);
const IndiastrategyV2 = loadable(() =>
  import("./organism/listingPage/indiastrategyV2")
);
const FabPlan = loadable(() => import("./pages/fabPlan"));

const PMS = loadable(() => import("./pages/preloginPages/landing/pms"));
const PassiveDetail = loadable(() =>
  import("./pages/preloginPages/listingPage/passiveDetail")
);
const PMSList = loadable(() =>
  import("./pages/preloginPages/category/pmslisting")
);
const Download = loadable(() => import("./pages/preloginPages/download"));
const Onboarding = loadable(() => import("./pages/Onboarding"));
const Schedule = loadable(() => import("./pages/schedule/sip"));
const KycNo = loadable(() => import("./pages/kyc/kycNo"));
const Home = loadable(() => import("./pages/home"));
const PaymentResp = loadable(() => import("./pages/transactionResponse"));
const PaymentOLResp = loadable(() =>
  import("./pages/transactionResponse/onlineResp")
);
const Profile = loadable(() => import("./pages/profile"));
const ManageBank = loadable(() => import("./pages/manageBank"));
const InvestmentCart = loadable(() => import("./pages/investmentCart"));
const KycScreen = loadable(() =>
  import("./organism/onboarding/KycScreen/kycyes")
);

const dashboard = loadable(() => import("./organism/Dashboard/index"));
const ActiveInve = loadable(() => import("./organism/Investor/index"));
const InvestDash = loadable(() => import("./organism/Dashboard/investerDash"));
const TrackOrder = loadable(() => import("./pages/trackorder"));
const text = loadable(() => import("./pages/textpage"));
const redemption = loadable(() => import("./pages/redeemPage"));
const transfer = loadable(() => import("./pages/stp"));
const withdraw = loadable(() => import("./pages/swp"));
const switchPage = loadable(() => import("./pages/switch"));
const ScheduleSip = loadable(() => import("./organism/schedule/sip"));
const ScheduleStp = loadable(() => import("./organism/schedule/stp"));
const ScheduleSwp = loadable(() => import("./organism/schedule/swp"));
const Reports = loadable(() => import("./pages/reports"));
const Cart = loadable(() => import("./organism/investmentCart"));
const PendingOnboard = loadable(() =>
  import("./organism/schedule/pendingonBoarding")
);
const BulkData = loadable(() => import("./organism/bulkTrans"));
const InitiatedHistory = loadable(() =>
  import("./organism/history/InitiatedHistory")
);
const TrasactionStatus = loadable(() => import("./pages/transactionStatus"));

const CustConfirmation = loadable(() => import("./pages/custConfirmation"));
const SessionExpire = loadable(() => import("./pages/sessionExpire"));
const InvestLumpsum = loadable(() => import("./pages/invest/investDist"));
const InvestSip = loadable(() => import("./pages/sip/sipDist"));
const DitReports = loadable(() => import("./pages/ditReports"));

const PrivacyPolicy = loadable(() => import('./pages/tnc/privacyPolicy'));
const SiteMap = loadable(()=> import('./pages/tnc/sitemap'))
const Disclaimer = loadable(() => import('./pages/tnc/disclaimer'));
const TermsnConditions = loadable(() => import('./pages/tnc/terms-n-conditions'));

const Feedback = loadable(() => import("./pages/feedback"));

// Media Room
const PressRelease = loadable(() => import('./pages/Media-Room/Press-Release'));
const News = loadable(() => import('./pages/Media-Room/News/news'));
const Interview = loadable(() => import('./pages/Media-Room/Interviews/Interview'));
const Articles = loadable(() => import('./pages/Media-Room/Press-Release/Articles'));
const NewsArticles = loadable(() => import('./pages/Media-Room/News/print/Articles'));
const InterviewArticles = loadable(() => import('./pages/Media-Room/Interviews/print/Articles'));
const AllPage = loadable(() => import('./organism/investonlinenew/all'));
const PmsPage = loadable(() => import('./organism/investonlinenew/pmsPage'));
const AIFPage = loadable(() => import('./organism/investonlinenew/aifPage'));
const Passcode = loadable(() => import('./organism/investonlinenew/Passcode'));
const Logout = loadable(() => import('./pages/logout/Logout'));

// Media Room
const AccountStatement = loadable(() =>
  import("./pages/reportsV2/AccountStatement")
);
const CapitalGainLoss = loadable(() =>
  import("./pages/reportsV2/CapitalGainLoss")
);
const Sip = loadable(() => import("./pages/reportsV2/Sip"));

// MANAGE INVESTOR ACCOUNT
const OTMPage = loadable(() => import("./pages/otm/OTM"));
const ReminderPage = loadable(() => import("./pages/reminder/Reminder"));

const loadClevertap = () => {
  // const CLEVERTAP_ACCOUNT_ID =
  //   process.env.NODE_ENV === "production" &&
  //     window.location.hostname === "partners.motilaloswalamc.com"
  //     ? "R95-R66-5Z7Z"//live
  //     :"TEST-K95-R66-5Z7Z";// uat;

  const scriptTag = document.createElement("script");
  scriptTag.id = "clevertap_script";
  scriptTag.type = "text/javascript";
  scriptTag.innerHTML = `var clevertap = {event:[], profile:[], account:[], onUserLogin:[], region:'in1', notifications:[], privacy:[]};
  // replace with the CLEVERTAP_ACCOUNT_ID with the actual ACCOUNT ID value from your Dashboard -> Settings page
  clevertap.account.push({"id": "${process.env.REACT_APP_CLEVERTAP_ACCOUNT_ID}"});
  clevertap.privacy.push({optOut: false}); //set the flag to true, if the user of the device opts out of sharing their data
  clevertap.privacy.push({useIP: false}); //set the flag to true, if the user agrees to share their IP data
  clevertap.notifications = { includePushId: true };
  (function () {
          var wzrk = document.createElement('script');
          wzrk.type = 'text/javascript';
          wzrk.async = true;
          wzrk.src = ('https:' == document.location.protocol ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com') + '/js/clevertap.min.js';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(wzrk, s);
  })();
  clevertap.spa = true
  window.clevertap = clevertap;`;
  // clevertap.event.push('navigated_to: ${pathName}');
  console.log("in loadClevertap");
  document.head.appendChild(scriptTag);
};
class Router extends Component {
  constructor(props) {
    super(props);
    this.tmr1 = createRef();
    this.tmr2 = createRef();
  }
  componentDidMount() {
    console.log("mounting components index");
    async function setupServiceWorker() {
      console.log("in setupServiceWorker");
      try {
        await registerServiceWorker();
        await registerPushNotifications();
      } catch (error) {
        console.log("setupServiceWorker", error);
      }
    }

    this.tmr1.current = setTimeout(loadClevertap, 200);

    this.tmr2.current = setTimeout(setupServiceWorker, 4000);
  }

  componentWillUnmount() {
    clearTimeout(this.tmr1.current);
    clearTimeout(this.tmr2.current);
    console.log("unmounting components index");
  }

  render() {
    return (
      <React.Fragment>
        {/* <Header /> */}

        <Switch>
          <PublicRoute restricted={false} component={Home} path="/" exact />
          <PublicRoute
            restricted={false}
            component={PaymentOLResp}
            path="/paymentResponse"
            exact
          />
          <PublicRoute restricted={false} component={Faq} path="/faq" />
          <PublicRoute restricted={false} component={KycNo} path="/KycNo" />
          <PublicRoute
            restricted={false}
            component={About}
            path="/about-us/:id"
          />
          <PublicRoute restricted={false} component={Contact} path="/Contact" />
          <PublicRoute restricted={false} component={Tools} path="/tools/:id" />
          <PublicRoute restricted={false} component={Nav} path="/Nav" />
          <PublicRoute
            restricted={false}
            component={Business}
            path="/InternationalBusiness"
          />
          <PublicRoute restricted={false} component={MFFund} path="/mf" exact />
          <PublicRoute
            restricted={false}
            component={FeedbackPreLogin}
            path="/contactUs"
          />
          {/* <PublicRoute restricted={false} component={CategoryPage} path="/mf/Category" /> */}
          <PublicRoute
            restricted={false}
            component={CategoryNew}
            path="/mf/Category"
          />
          <PublicRoute
            restricted={false}
            component={Compare}
            path="/mf/Compare"
          />
          <PublicRoute
            restricted={false}
            component={MFDetail}
            path="/mf/mfdetail"
          />
          <PublicRoute
            restricted={false}
            component={PassiveFund}
            path="/PassiveFund"
          />
          <PublicRoute
            restricted={false}
            component={PassiveDetail}
            path="/passivedetail"
          />
          <PublicRoute
            restricted={false}
            component={AccountStmt}
            path="/AccountStatement"
          />
          <PublicRoute
            restricted={false}
            component={PMSDetail}
            path="/pmsdetail"
          />
          <PublicRoute
            restricted={false}
            component={PMSBusiness}
            path="/PMSbusiness"
          />
          <PublicRoute restricted={false} component={PMSNext} path="/PMSNext" />
          <PublicRoute
            restricted={false}
            component={Indiastrategy}
            path="/Indiastrategy"
          />
          <PublicRoute
            restricted={false}
            component={IndiastrategyV2}
            path="/IndiastrategyV2"
          />
          <PublicRoute
            restricted={false}
            component={PrivacyPolicy}
            path="/privacy-policy"
          />
          <PublicRoute
            restricted={false}
            component={Disclaimer}
            path="/disclaimer"
          />
          <PublicRoute
            restricted={false}
            component={TermsnConditions}
            path="/terms-n-conditions"
          />

          <PublicRoute restricted={false} component={PMS} path="/PMS" />
          <PublicRoute restricted={false} component={PMSList} path="/PMSListing" />
          <PublicRoute restricted={false} component={Download} path="/Download/:id/:id" />
          <PublicRoute restricted={false} component={InvestOnline} path="/investonline/:id" />
          <PublicRoute restricted={false} component={PasswordResetSuccess} path="/resetSuccess"/>
          <PublicRoute restricted={false} component={PasswordattemptReached} path="/maxattemptReached"/>
          <PublicRoute restricted={false} component={OtpattemptReached} path="/OtpAttemptReached"/>
          <PublicRoute restricted={false} component={Passcode} path="/passcode" />

          {/* <PublicRoute restricted={false} component={Profile} path="/Profile" /> */}
          <PublicRoute
            restricted={false}
            component={Schedule}
            path="/schedule/:id"
          />
          <PublicRoute
            restricted={false}
            component={ManageBank}
            path="/manage-account/:id"
          />
          {/* <PublicRoute restricted={false} component={InvestmentCart} path="/Cart" /> */}
          <PublicRoute
            restricted={false}
            component={CustConfirmation}
            path="/confirmation"
          />
          <PublicRoute
            restricted={false}
            component={SessionExpire}
            path="/sessionExpired"
          />
          {/* <PublicRoute restricted={false} component={Onboarding} path="/onboarding/:id" /> */}
          {/* <PublicRoute restricted={false} component={KycScreen} path="/KycScreen" /> */}

          <PrivateRoute restricted={true} component={AllPage} exact path="/dashboard"/>
          <PrivateRoute restricted={true} component={PmsPage} path="/pmsPage" />
          <PrivateRoute restricted={true} component={AIFPage} path="/aifPage" />
          <PrivateRoute restricted={true} component={Logout} path="/logout" />

          <PrivateRoute
            restricted={true}
            component={Onboarding}
            path="/create-new-folio/onboarding/:id"
          />
          <PrivateRoute
            restricted={true}
            component={CategoryNew}
            path="/quick-invest"
          />
          <PrivateRoute
            restricted={true}
            component={MFDetail}
            path="/dashboard/mfdetail"
          />
          <PrivateRoute
            restricted={true}
            component={PassiveDetail}
            path="/dashboard/passivedetail"
          />
          <PrivateRoute
            restricted={true}
            component={KycScreen}
            path="/create-new-folio"
          />
          <PrivateRoute
            restricted={true}
            component={dashboard}
            exact
            path="/business-dashboard"
          />
          {/* InvestLumpsum */}
          <PrivateRoute
            restricted={true}
            component={InvestLumpsum}
            exact
            path="/active-investor/additional-purchase"
          />
          <PrivateRoute
            restricted={true}
            component={InvestSip}
            exact
            path="/active-investor/sip"
          />
          <PrivateRoute restricted={true} component={Cart} path="/Cart" />
          <PrivateRoute
            restricted={true}
            component={ActiveInve}
            path="/investor-list/:id"
          />
          {/* <PrivateRoute restricted={true} name="activeSIP" component={ActiveInve} path="/activeinvestor/activeSIP" /> */}
          <PrivateRoute
            restricted={true}
            component={InvestDash}
            path="/investorDashboard"
          />
          <PrivateRoute
            restricted={true}
            component={TrackOrder}
            path="/initiated-transaction-history"
          />
          <PrivateRoute
            restricted={true}
            component={redemption}
            path="/active-investor/redeem"
          />
          <PrivateRoute
            restricted={true}
            component={transfer}
            path="/active-investor/stp"
          />
          <PrivateRoute
            restricted={true}
            component={withdraw}
            path="/active-investor/swp"
          />
          <PrivateRoute
            restricted={true}
            component={switchPage}
            path="/active-investor/switch"
          />
          <PrivateRoute
            restricted={true}
            component={FabPlan}
            path="/active-investor/fab-plan"
          />
          <PrivateRoute
            restricted={true}
            component={OTMPage}
            path="/active-investor/otm"
          />
          <PrivateRoute
            restricted={true}
            component={ReminderPage}
            path="/active-investor/reminder/:id?"
          />
          <PrivateRoute restricted={true} component={Profile} path="/profile" />

          <PrivateRoute
            restricted={true}
            component={ScheduleSip}
            path="/manage-investments-sip"
          />
          <PrivateRoute
            restricted={true}
            component={ScheduleStp}
            path="/manage-investments-stp"
          />
          <PrivateRoute
            restricted={true}
            component={ScheduleSwp}
            path="/manage-investments-swp"
          />
          <PrivateRoute
            restricted={true}
            component={Reports}
            path="/dashboard/reports/:id"
          />
          <PrivateRoute
            restricted={true}
            component={PendingOnboard}
            path="/dashboard/pendingData"
          />
          <PrivateRoute
            restricted={true}
            component={BulkData}
            path="/bulk-transaction"
          />
          <PrivateRoute
            restricted={true}
            component={InitiatedHistory}
            path="/dashboard/InitiatedHistory"
          />

          <PrivateRoute restricted={true} component={text} path="/text" />
          <PrivateRoute
            restricted={true}
            component={TrasactionStatus}
            path="/transaction-status"
          />

          <PrivateRoute
            restricted={true}
            component={DitReports}
            path="/dit-reports"
          />
          <PrivateRoute
            restricted={true}
            component={Feedback}
            path="/feedback"
          />

          {/* Media Room */}
          <PublicRoute
            restricted={false}
            component={PressRelease}
            path="/Media-Room/Press-Release"
            exact
          />
          <PublicRoute
            restricted={false}
            component={News}
            path="/Media-Room/News/:newsId"
            exact
          />
          <PublicRoute
            restricted={false}
            component={Interview}
            path="/Media-Room/Interviews/:interviewId"
            exact
          />
          <PublicRoute
            restricted={false}
            component={Articles}
            path="/Media-Room/Press-Release/:pressReleaseId"
            exact
          />
          <PublicRoute
            restricted={false}
            component={NewsArticles}
            path="/Media-Room/News/Print/:newsArticleId"
            exact
          />
          <PublicRoute
            restricted={false}
            component={InterviewArticles}
            path="/Media-Room/Interviews/Print/:interviewArticleId"
            exact
          />

          {/* Reports */}
          <PrivateRoute
            restricted={true}
            component={AccountStatement}
            path="/reports/account-statement"
          />

          <Route>
            <NoMatch />
          </Route>
        </Switch>
        {/* <Footer /> */}
      </React.Fragment>
    );
  }
}

export default process.env.NODE_ENV === "development" ? hot(Router) : Router;
