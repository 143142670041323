import { ListItemIcon, Menu, MenuItem, Typography, withStyles } from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import { useState } from 'react';
// import { useRouter } from 'next/router';

const loginButtonStyle = {
  default: "tw-flex tw-items-center tw-bg-transparent tw-h-7 tw-border tw-border-solid tw-border-login tw-rounded-lg tw-outline-none focus:tw-outline tw-outline-gray-500 tw-px-3 tw-gap-2 hover:tw-bg-gray-100 tw-text-login tw-font-semibold tw-text-[14px] tw-leading-6 desktop:tw-text-sm",
  outlined: "tw-bg-transparent tw-flex tw-items-center tw-capitalize tw-font-medium tw-text-sm tw-px-4 tw-py-1 tw-text-login tw-border tw-border-solid  tw-border-login tw-rounded-lg tw-outline-none focus:tw-outline tw-outline-gray-500 tw-gap-2",
  outlinedDark: "tw-flex tw-items-center tw-capitalize tw-font-medium tw-text-sm tw-px-4 tw-py-1 tw-bg-white tw-text-login tw-border tw-border-solid tw-border-login tw-rounded-lg tw-outline-none focus:tw-outline tw-outline-gray-500 tw-gap-2"
};

// variant = "default" | "outlined"
const LoginDropdown = ({ variant = "default" }) => {
  const [clickedLogin, setclickedLogin] = useState(null);
  // const router = useRouter();
  const open = Boolean(clickedLogin);

  const handleOpenMenu = (event) => {
    setclickedLogin(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setclickedLogin(null);
  };

  const handleLoginRedirect = (path) => {
    handleCloseMenu();
    window.location.href = path
  };

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      margin: '0 24px',
      padding: '16px 16px 16px 0',

      '& .MuiListItemIcon-root': {
        color: '#221F1E',
        minWidth: '36px'
      },

      '& .MuiTypography-body1': {
        fontSize: '14px',
        fontWeight: 500,
      }

    },
  }))(MenuItem);

  return (
    <>
      <button
        id="login-button"
        aria-controls={open ? 'login-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpenMenu}
        className={loginButtonStyle[variant]}
      >
        <span><img src="/assets/images/header/UserCircle.png" alt="User Circle" height={18} width={18} className='tw-block' /></span>
        Login
        <span><img src="/assets/images/header/CaretDown.png" alt="Caret Down" height={16} width={16} className='tw-block' /></span>
      </button>

      {/* Dropdown menu */}
      <Menu
        id="login-menu"
        anchorEl={clickedLogin}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'login-button',
        }}
        disableScrollLock={true}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* Investor Login option */}
        <StyledMenuItem onClick={() => handleLoginRedirect(`${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}mutualfund/login`)} className='tw-py-4 tw-pl-0 tw-pr-16 tw-mx-6 custom-border'>
          <ListItemIcon className='tw-text-black'>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <Typography className='tw-text-sm tw-font-medium'>Investor Login</Typography>
        </StyledMenuItem>

        {/* Partner Login option */}
        <StyledMenuItem onClick={() => handleLoginRedirect(`${process.env.REACT_APP_MOTILAL_PARTNER_AMC_MODULE_URL}investonline/dist`)} className='tw-py-4 tw-pl-0 tw-pr-16 tw-mx-6'>
          <ListItemIcon className='tw-text-black'>
            <AssignmentIndIcon fontSize="small" />
          </ListItemIcon>
          <Typography className='tw-text-sm tw-font-medium'>Partner Login</Typography>
        </StyledMenuItem>
      </Menu>
    </>
  );
};

export default LoginDropdown;
