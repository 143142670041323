import React, { useState, useEffect } from "react";
import Image from "../../../components/atom/image";
import { Link, withRouter, useHistory } from "react-router-dom";
import "./shared.css";
import { useDispatch, useSelector } from "react-redux";
import ProductJsonData from "../products.json";
import loginApi from "../../../services/loginApi";
import { authDetails, loginTypeCheck } from "../.../../../../redux/actions/trans/authAction";
import DropdownComponent from "../../../components/common/Dropdown";
import { getDecryptedItem, setEncryptedItem } from "../../../helper/methods";

let api = new loginApi();

const Navbar = (props) => {
  const history = useHistory();
  const [navType, setNavType] = useState("");
  const [productData, setProductData] = useState([]);

  let dispatch = useDispatch();
  let getArn = useSelector((state) => state.Auth.arnDetails);
  let productNames = localStorage.getItem("onlyProductNames");
  let redirectedProduct = localStorage.getItem("redirectDitUrl");
  // let panNumber = localStorage.getItem("uniqueKey");
  let panNumber = getDecryptedItem("PANentered");
  const [selectedProduct, setselectedProduct] = useState('')

  // setselectedProduct(redirectedProduct);

  const productsJson = [
    {
      Heading: "ALL",
      url: "/dashboard",
      internal: true,
      filter: "ALL",
    },
    {
      Heading: "MF",
      url: "/business-dashboard",
      internal: true,
      filter: "MF",
    },
    {
      Heading: "PMS",
      url: "/pmsPage",
      internal: false,
      filter: "PMS",
    },
    {
      Heading: "AIF",
      url: "/aifPage",
      internal: false,
      filter: "AIF",
    },
  ];

  const [hit, setHit] = useState(false);

  const navTypeClick = (data) => {
    if (navType === data) {
      setNavType("");
    } else {
      setNavType(data);
    }
  };
  const handleLogout = () => {
    props.logout();
    // props.history.push({
    //     pathname: '/mf',
    //     // your data array of objects
    // })
  };
  const closePOpUp = () => {
    // setHit(hit?false:true)
    let getNavbar = document.querySelectorAll(".navbarTop .nav-item");
    console.log(getNavbar, "getNavbar");
    getNavbar.forEach((curr) => {
      console.log(curr.childNodes, "Sujeet");
      if (curr.childNodes[1] !== undefined) {
        curr.childNodes[1].classList.remove("show");
      }

      //  let checkClass= curr.getElementByClassName('.show');
      //console.log(checkClass,'checkClass')
    });
  };
  useEffect(() => {
    console.log(props, "navbarProps");
    document.getElementById("mobileBAR").classList.remove("open");
  }, [props]);
  useEffect(() => {
    //document.querySelector('button.navbar-toggler.navbar-toggler-right').classList.toggle('open');

    let getNavbar = document.querySelectorAll(".navbarTop .nav-item");
    getNavbar.forEach((el) => {
      el.addEventListener("mouseover", function (curr) {
        console.log(el.childNodes, "el.childNodes", curr);
        if (el.childNodes[1] !== undefined) {
          el.childNodes[1].classList.add("show");
          el.classList.add("show");
        }
      });
      el.addEventListener("mouseout", function (curr) {
        console.log(el.childNodes, "el.childNodesover", curr);
        if (el.childNodes[1] !== undefined) {
          el.childNodes[1].classList.remove("show");
          el.classList.remove("show");
        }
      });
    });
  }, []);

  useEffect(() => {
    setselectedProduct(redirectedProduct);
  }, [redirectedProduct]);

  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
    document
      .querySelector("button.navbar-toggler.navbar-toggler-right")
      .classList.toggle("open");
  };

  let localData = getDecryptedItem("uniqueKey");
  let customerName = "";
  if (getArn) {
    customerName = getArn.fName;
    // customerName = jsonData.nameAsOnPan;
  }
  // if (localData) {
  //     let jsonData = JSON.parse(localData);
  //     customerName =getArn.fName
  //    // customerName = jsonData.nameAsOnPan;
  // }

  const productNamesArray = productNames?.split(",");

  // console.log("checking at line 24: ", productNamesArray, productNamesArray.length)

  useEffect(() => {
    const filteredProducts = productsJson.filter((product) => {
      if (productNamesArray?.length === 1) {
        if (productNamesArray?.includes(product.filter)) {
          return true;
        }
        return false;
      } else {
        if (product.filter === "ALL" || productNamesArray?.includes(product.filter)) {
          return true;
        }
        return false;
      }
    });

    setProductData(filteredProducts);
  }, [productNames]);

  useEffect(() => {
    let parsedPanData = panNumber;
    let productDtlsFromLS = getDecryptedItem('productDtls');
    let productDetailsData = productDtlsFromLS?.productDtls?.filter(obj => obj.productType === 'MF');
    let fName = productDetailsData?.length ? productDetailsData[0].fName : null;
    setEncryptedItem("uniqueKey", {panNo: parsedPanData, nameAsOnPan: fName || '', productType: 'MF'});
    setEncryptedItem('userInfo', productDetailsData);
  }, [])

  const handleClick = (data) => {
    let parsedPanData = panNumber;
    console.log("checking at line 149: ", parsedPanData);

    let userIdFromLS = getDecryptedItem("PANentered")
    let loginWithFromLS = localStorage.getItem("LoginTypeentered")

    let productDtls = getDecryptedItem("productDtls");
    let productDtlsParsed = productDtls;
    let ProductData = productDtlsParsed?.productDtls;
    const filterValue = data?.filter;
    console.log(filterValue, "Filter value: ")
    setselectedProduct(filterValue);
    localStorage.setItem("redirectDitUrl", filterValue);
    let arnCodeValue;
    if (filterValue === "ALL") {
      arnCodeValue = ProductData?.find(
        (product) => product?.productType === "MF"
      )?.arnno;
    } else {
      const matchingProduct = ProductData?.find(
        (product) => product?.productType === filterValue
      );
      arnCodeValue = matchingProduct?.arnno;
    }
    console.log(
      "checking at line 156: ",
      filterValue,
      ProductData,
      arnCodeValue
    );

    if (arnCodeValue) {
      localStorage.setItem("ARNCode", arnCodeValue);
      let isArn = false;
      let arnCodeLowercase = arnCodeValue?.toLowerCase();
      let arnCodeSplit = arnCodeLowercase?.split('-');
      if (arnCodeSplit?.includes('arn')) {
        isArn = false;
      } else {
        isArn = true;
      }
      dispatch(loginTypeCheck(isArn));
    }

    let productDtlsFromLS = getDecryptedItem('productDtls');
    if (filterValue === "MF") {
      let productDetailsData = productDtlsFromLS?.productDtls?.filter(obj => obj.productType === 'MF');
      let { fName } = productDetailsData;
      setEncryptedItem("uniqueKey", {panNo: parsedPanData, nameAsOnPan: fName, productType: filterValue});
      setEncryptedItem('userInfo', productDetailsData)
      history.push("/business-dashboard");
    } else if (filterValue === "ALL") {
      let productDetailsData = productDtlsFromLS?.productDtls?.filter(obj => obj.productType === 'MF');
      let { fName } = productDetailsData;
      setEncryptedItem("uniqueKey", {panNo: parsedPanData, nameAsOnPan: fName, productType: filterValue});
      setEncryptedItem('userInfo', productDetailsData);
      history.push("/dashboard");
    } else if (filterValue === "PMS") {
      let productDetailsData = productDtlsFromLS?.productDtls?.filter(obj => obj.productType === 'PMS');
      let productArnNo = productDetailsData?.arnno;
      let { fName } = productDetailsData;
      setEncryptedItem("uniqueKey", {panNo: parsedPanData, nameAsOnPan: fName, productType: filterValue});
      setEncryptedItem('userInfo', productDetailsData)
      // history.push("/pmsPage");
      window.location.href = window.location.origin + "/v2/pms";
    } else if (filterValue === "AIF") {
      let productDetailsData = productDtlsFromLS?.productDtls?.filter(obj => obj.productType === 'AIF');
      let productArnNo = productDetailsData?.arnno;
      let { fName } = productDetailsData;
      setEncryptedItem("uniqueKey", {panNo: parsedPanData, nameAsOnPan: fName, productType: filterValue});
      setEncryptedItem('userInfo', productDetailsData)
      // history.push("/aifPage");
      window.location.href = window.location.origin + "/v2/aif";
    }
  };

  console.log(
    "checking at line 108: ",
    productNames,
    productNamesArray,
    productData,
    selectedProduct,
    redirectedProduct
  );

  return (
    <React.Fragment>
      <nav className="navbar">
        {/* <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <a className="navbar-brand brand-logo" href="/business-dashboard">
          
            <Image image="nav/moamc-logo-new.png" alt="logo" />
          </a>
          <a
            className="navbar-brand brand-logo-mini"
            href="/business-dashboard"
          >
            <Image image="nav/mf-m-min-logo.svg" alt="logo" />
         
          </a>
        </div> */}
        <div className="navbar-menu-wrapper d-flex justify-content-between">
          {/* <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
            onClick={() => {
              if (selectedProduct !== "ALL") {
                document.body.classList.toggle("sidebar-icon-only");
              } else {
                document.body.classList.add("sidebar-icon-only");
              }
            }}
          >
            <span
              className={`${selectedProduct !== "ALL" ? "mdi mdi-menu " : ""}`}
            ></span>
          </button> */}
          <ul className="navbar-nav navbar-nav-left navbarTop">
            {/* <a className="navbar-brand nav-logo" href="/business-dashboard">

              <Image image="nav/moamc-logo-new.png" alt="logo" width='113' />
            </a> */}
            <DropdownComponent productData={productData} handleClick={handleClick} selectedProduct={selectedProduct} />
          </ul>

          <div className="d-flex">
            <ul className="navbar-nav navbar-nav-right navbarTop d-flex">

              <li className="nav-item d-none">
                <Link
                  to="/Cart"
                  className="nav-link count-indicator "
                  id="cartDropdown"
                >
                  <i className="mastercart_headericon"></i>{" "}
                  <div className="cart-text">Master Cart</div>
                  {/* <span class="badge badge-up badge-pill">6</span> */}
                </Link>
              </li>
              {selectedProduct !== "ALL" ? (
                <li className="nav-item">
                  <Link
                    to="/Cart"
                    className="nav-link count-indicator "
                    id="cartDropdown"
                  >
                    <i className="mastercart_headericon"></i>{" "}
                    <div className="cart-text">Master Cart</div>
                    {/* <span class="badge badge-up badge-pill">6</span> */}
                  </Link>
                </li>
              ) : (
                ""
              )}
              <li className="nav-item nav-profile dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  id="profileDropdown"
                  href="#"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="nav-profile-img">
                    <Image image="nav/profile-icon.png" alt="image" />
                    {/* <img src="assets/images/faces/face28.png" alt="image"> */}
                  </div>
                  {/* <div className="nav-profile-text">
                  <p className="mb-1 text-black">{customerName}</p>
                </div> */}
                </a>
                <div
                  className="dropdown-menu navbar-dropdown dropdown-menu-right p-0 border-0 font-size-sm"
                  aria-labelledby="profileDropdown"
                  data-x-placement="bottom-end"
                >
                  {/* <div className="p-3 text-center bg-primary">
            
            <Image class="img-avatar img-avatar48 img-avatar-thumb" image="header/face/face28.png" alt="image"/>
          </div> */}
                  <div className="p-2">
                    <h5
                      onClick={closePOpUp}
                      className="dropdown-header  text-uppercase pl-2 text-dark"
                    >
                      User Options
                    </h5>
                    {/* <a className="dropdown-item py-1 d-flex align-items-center justify-content-between" href="#">
              <span>Inbox</span>
              <span className="p-0">
                <span className="badge badge-primary">3</span>
                <i className="mdi mdi-email-open-outline ml-1"></i>
              </span>
            </a> */}

                    <Link to="/profile?euinlist" onClick={closePOpUp} className="dropdown-item py-1 d-flex align-items-center justify-content-between">
                      <span>EUIN List</span>

                    </Link>

                    <Link to="/profile" onClick={closePOpUp} className="dropdown-item py-1 d-flex align-items-center justify-content-between">
                      <span>Profile</span>
                      <span className="p-0">
                        {/* <span className="badge badge-success">1</span> */}
                        <i className="mdi mdi-account-outline ml-1"></i>
                      </span>
                    </Link>
                    {/* <a className="dropdown-item py-1 d-flex align-items-center justify-content-between" href="javascript:void(0)">
              <span>Settings</span>
              <i className="mdi mdi-settings"></i>
            </a> */}
                    <div role="separator" className="dropdown-divider"></div>
                    <h5 className="dropdown-header text-uppercase  pl-2 text-dark mt-2">
                      Actions
                    </h5>
                    {/* <a className="dropdown-item py-1 d-flex align-items-center justify-content-between" href="#">
              <span>Lock Account</span>
              <i className="mdi mdi-lock ml-1"></i>
            </a> */}
                    <a
                      className="dropdown-item py-1 d-flex align-items-center justify-content-between"
                      onClick={handleLogout}
                    >
                      <span>Log Out</span>
                      <i className="mdi mdi-logout ml-1"></i>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
            <button
              className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
              id="mobileBAR"
              type="button"
              data-toggle="offcanvas"
              onClick={() => {
                if (selectedProduct !== "ALL") {
                  toggleOffcanvas();
                }
              }}
            >
              <span
                className={`${selectedProduct !== "ALL" ? "mdi mdi-menu " : ""}`}
              ></span>
            </button>
          </div>
        </div>
        <div className="nav-profile-text ">
          <p className="mb-1 text-black"><span>Welcome, </span> <span className="font-weight-bold">{customerName}</span></p>
        </div>

      </nav>
    </React.Fragment>
  );
};

export default withRouter(Navbar);
