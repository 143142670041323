import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
// import Link from 'next/link';

const ProductList = ({ label, name, value, onChange, options, defaultValue, className, ...props }) => {
  return (
    <TextField
      select
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      variant="outlined"
      fullWidth
      className={className}
      defaultValue={defaultValue}
      SelectProps={{
        IconComponent: props.customicon,
        MenuProps: {
          disableScrollLock: true,
        },
        renderValue: (selectedValue) => options.filter(item => item.value === selectedValue)[0]?.label
      }}
      {...props}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value} sx={{ padding: '0' }}>
          <a
            href={option.link}
            style={{ textDecoration: 'none', color: 'inherit', backgroundColor: 'inherit', padding: '6px 16px', width: '100%' }}
          >
            {option.fullText || option.label}
          </a>
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ProductList;