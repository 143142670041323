import React from 'react'
import AppsAndSocials from "./AppsAndSocials"
import Branding from "./Branding"
import { copyrightLinks } from "./constants"
import Contact from "./Contact"
import KnowMore from "./KnowMore"
import Links from "./Links"
import "./footerRevamp.css"

const index = () => {
  return (
    <footer className="tw-bg-primaryBlue tw-relative tw-text-white footerRevamp tw-pt-5 md:tw-pt-16 tw-pb-6">
      <div className="container tw-grid tw-grid-cols-1 tw-z-0">

        {/* TOP Section */}
        <div className="tw-grid footer-wrapper-column-template tw-gap-x-7 tw-gap-y-10 md:tw-mb-[60px]">
          {/* Left Section */}
          <div className="">
            <Branding />
            <AppsAndSocials />
            <Contact />
          </div>
          {/* Right Section */}
          <Links />
        </div>

        {/* BOTTOM Section */}
        {/* <KnowMore /> */}

        {/* Disclaimer */}
        <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-items-center tw-gap-4 tw-justify-between tw-mt-1 tw-pb-6 md:tw-pt-10 md:tw-pb-0 md:tw-border-t max-md:tw-border-b tw-border-white tw-border-opacity-[30%] tw-z-[2]">
          <p className="tw-text-xs tw-text-footerText md:tw-max-w-[65%] tw-m-0">
            Mutual Fund investments are subject to market risks, read all scheme related documents carefully.<br />
            KYC is one time exercise while dealing in securities markets - once KYC is done through a SEBI registered intermediary (broker, DP, Mutual Fund etc), you need not undergo the same process again when you approach another intermediary.
          </p>
          <p className="tw-text-xs tw-font-medium tw-text-footerText tw-text-nowrap tw-m-0 tw-text-borderColor tw-text-md">
            CIN-U67120MH2008PLC188186
          </p>
        </div>

        {/* Copyright */}
        <div className="tw-mt-9 tw-flex max-md:tw-flex-col tw-justify-between tw-gap-x-7">
          <p className="tw-text-xs tw-text-footerWhite tw-font-normal tw-m-0">
            © Copyright {new Date().getFullYear()} Motilal Oswal Asset Management Company Pvt. Ltd.
          </p>
          <div className="max-md:tw-mt-3 tw-flex tw-items-center tw-gap-x-4 md:tw-gap-x-7 tw-flex-wrap">
            {copyrightLinks.map((item, i) => <a key={i} href={item.link} title={item.title} className="tw-text-nowrap tw-text-sm max-md:tw-leading-6 md:tw-text-xs hover:md:tw-underline tw-text-footerWhite tw-font-normal hover:tw-text-footerText tw-text-sm "  target={item.external ? "_blank" : "_self"}>{item.title}</a>)}
          </div>
        </div>
      </div>

    </footer>
  )
}

export default index