import React, { useEffect, useState } from "react";
import { Link, withRouter } from 'react-router-dom';
import Image from "../../../components/atom/image";
import {

  isMobile
} from "react-device-detect";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const Sidebar = (props) => {
  const [open, setOpen] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [isLessThan992, setIsLessThan992] = useState(window.innerWidth < 992);
  console.log(props, 'privateRoutre', props.location.pathname)
  let getpathName = props.location.pathname;
  const isPathActive = (getpathName) => {
    //console.log(getpathName, 'isPathActivePATH')
    return props.location.pathname.startsWith(getpathName);
  }

  let productType = localStorage.getItem('redirectDitUrl');

  const menuHandler = (data, boolVal, link, label) => { //This function is called when clicked on main menus

    if (label === "Feedback" || label === "Manual") {
      window.open(link, "_blank")
    }
    if (open === data) {
      setOpen('')
    } else {
      setOpen(data)
    }

    if (boolVal === 0 || productType === 'ALL') {
      document.body.classList.add('sidebar-icon-only');
      document.querySelector('.sidebar-offcanvas').classList.remove('active');
    }

  }

  useEffect(() => {
    if (productType === 'ALL') {
      document.body.classList.add('sidebar-icon-only');
    }
  }, [productType])

  useEffect(() => {
    if (document.body.classList.contains("sidebar-icon-only")) {
      setSidebarOpen(false)
    } else {
      setSidebarOpen(true)
    }
  }, [])

  const handleResize = () => {
    setIsLessThan992(window.innerWidth < 992);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // added by Nikesh
  const menuHandlertwist = () => {  //This function is called when clicked on submenus

    document.body.classList.add('sidebar-icon-only');
    document.querySelector('.sidebar-offcanvas').classList.remove('active');
  }

  useEffect(() => {
    const body = document.querySelector('body');
    document.querySelector('.nav-item').remove('hover-open');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          // alert('mouseover')
          console.log("checking at line 54 a")
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }, [])

  let getOption = props.option;

  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
    document
      .querySelector("button.navbar-toggler.navbar-toggler-right")
      .classList.toggle("open");
  };

  console.log(sidebarOpen, "Sidebar open")

  return (
    <React.Fragment>
      <nav class={`sidebar sidebar-offcanvas ${productType === 'ALL' ? 'invisible' : ''}`} id="sidebar">
        <div className={`d-flex justify-content-around ${sidebarOpen ? 'logo-row-container' : 'logo-col-container'}`}>
          <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            {
              document.body.classList.contains("sidebar-icon-only") ? <a
                className="navbar-brand brand-logo-mini"
                href="/business-dashboard"
              >
                <Image image="nav/MO_icon.webp" alt="logo" width="113" />

              </a> : <a className="navbar-brand brand-logo" href="/business-dashboard">

                <Image image="nav/MO_Asset_Management_Logo.webp" alt="logo" width='113' />
              </a>
            }
          </div>

          {!isLessThan992 ? <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
            onClick={() => {
              setSidebarOpen(!sidebarOpen)
              if (productType !== "ALL") {
                document.body.classList.toggle("sidebar-icon-only");
              } else {
                document.body.classList.add("sidebar-icon-only");
              }
            }}
          >
            {
              sidebarOpen ?
                <Image image="nav/ic_arrow-circle-left.svg" alt="logo" />
                :
                <Image image="nav/ic_arrow-circle-right.svg" alt="logo" />
            }
          </button> : <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            id="mobileBAR"
            type="button"
            data-toggle="offcanvas"
            onClick={() => {
              if (productType !== "ALL") {
                toggleOffcanvas();
              }
            }}
          >
            <span
              className={`${productType !== "ALL" ? "mdi mdi-menu " : ""}`}
            ></span>
          </button>}

        </div>
        <ul class="nav">

          {getOption.map((curr, index) => {
            let { menu, subMenu } = curr;
            let active = false;
            let innerActive = false;
            active = isPathActive(curr.menu.linkTo);

            let innermenu = "";
            let SubmenuLink = "";
            let innermenuCall = 0;
            if (subMenu) {
              innermenuCall = 1;
              innermenu = subMenu.map((Cursub, index1) => {
                console.log(Cursub.menu.linkTo, 'Cursub', getpathName)
                innerActive = getpathName === Cursub.menu.linkTo;
                if (getpathName === Cursub.menu.linkTo) {
                  SubmenuLink = Cursub.menu.linkTo;
                }

                return (
                  <li class="nav-item">
                    <Link class={`nav-link ${getpathName === Cursub.menu.linkTo ? "active" : ""}`} to={Cursub.menu.linkTo} onClick={() => menuHandlertwist()}>
                      {Cursub.menu.label}
                    </Link>
                  </li>
                )

              })
            }
            let tag = `Link`;
            if (curr.menu.label === "Feedback") {
              tag = `a`
            }
            console.log(innerActive, 'SideBarinnerActive,', SubmenuLink, '==', getpathName)
            return (
              <li key={index} className={`nav-item ${getpathName === curr.menu.linkTo || SubmenuLink === getpathName ? "active " : ""} ${open === 'menu' + index ? isMobile ? "hover-open open" : "open" : ""}`}>
                <Link to={curr.menu.url ? "#" : innermenu == "" ? curr.menu.linkTo : "#"}
                  className={`nav-link ${innermenu !== "" ? "dropsubmenu" : null}`}
                  onClick={() => menuHandler(`menu${index}`, innermenuCall, curr.menu.linkTo, curr.menu.label)}>
                  <span className="icon-bg">
                    {/* <i className="mdi mdi-crosshairs-gps menu-icon"></i> */}
                    <i className={curr.menu.class}></i>
                  </span>
                  <span className="menu-title">{curr.menu.label}</span>
                  {innermenu !== "" ? <i class="menu-arrow"></i> : null}
                </Link>
                {innermenu !== "" ?
                  <div className={`collapse ${open === 'menu' + index ? 'show' : null}`} >
                    <ul className="nav flex-column sub-menu">{innermenu}</ul>
                  </div>
                  : null}

              </li>
            )
          })
          }

          {/* <li class="nav-item">
            <a class="nav-link" >
              <span class="icon-bg">
                <i class="mdi mdi-cube menu-icon"></i>
              </span>
              <span class="menu-title">Dashboard</span>
            </a>
          </li> */}
          {/* <li class="nav-item">
            <a
              class="nav-link"
              data-toggle="collapse"
             
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <span class="icon-bg">
                <i class="mdi mdi-crosshairs-gps menu-icon"></i>
              </span>
              <span class="menu-title">UI Elements</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="collapse" id="ui-basic">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                 
                  <a class="nav-link" >
                    Buttons
                  </a>
                </li>
                <li class="nav-item">
                  
                  <a class="nav-link" >
                    Dropdowns
                  </a>
                </li>
                <li class="nav-item">
                  
                  <a class="nav-link" >
                    Typography
                  </a>
                </li>
              </ul>
            </div>
          </li> */}
          {/* <li class="nav-item">
            <a class="nav-link" >
              <span class="icon-bg">
                <i class="mdi mdi-contacts menu-icon"></i>
              </span>
              <span class="menu-title">Icons</span>
            </a>
          </li> */}
          {/* <li class="nav-item">
            <a class="nav-link" >
              <span class="icon-bg">
                <i class="mdi mdi-format-list-bulleted menu-icon"></i>
              </span>
              <span class="menu-title">Forms</span>
            </a>
          </li> */}
          {/* <li class="nav-item">
            <a class="nav-link" >
              <span class="icon-bg">
                <i class="mdi mdi-chart-bar menu-icon"></i>
              </span>
              <span class="menu-title">Charts</span>
            </a>
          </li> */}
          {/* <li class="nav-item">
            <a class="nav-link" >
              <span class="icon-bg">
                <i class="mdi mdi-table-large menu-icon"></i>
              </span>
              <span class="menu-title">Tables</span>
            </a>
          </li> */}
          {/* <li class="nav-item">
            <a
              class="nav-link"
              data-toggle="collapse"
              
              aria-expanded="false"
              aria-controls="auth"
            >
              <span class="icon-bg">
                <i class="mdi mdi-lock menu-icon"></i>
              </span>
              <span class="menu-title">User Pages</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="collapse" id="auth">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  
                  <a class="nav-link" >
                    
                    Blank Page
                  </a>
                </li>
                <li class="nav-item">
                  
                  <a class="nav-link" >
                    
                    Login
                  </a>
                </li>
                <li class="nav-item">
                  
                  <a class="nav-link" >
                    
                    Register
                  </a>
                </li>
                <li class="nav-item">
                  
                  <a class="nav-link" >
                    
                    404
                  </a>
                </li>
                <li class="nav-item">
                  
                  <a class="nav-link" >
                    
                    500
                  </a>
                </li>
              </ul>
            </div>
          </li> */}
          {/* <li class="nav-item documentation-link">
            <a
              class="nav-link"
              
              target="_blank"
            >
              <span class="icon-bg">
                <i class="mdi mdi-file-document-box menu-icon"></i>
              </span>
              <span class="menu-title">Documentation</span>
            </a>
          </li> */}
          {/* <li class="nav-item sidebar-user-actions">
            <div class="user-details">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <div class="d-flex align-items-center">
                    <div class="sidebar-profile-img">
                     
                      <Image image="header/face/face28.png" alt="image" />
                    </div>
                    <div class="sidebar-profile-text">
                      <p class="mb-1">Henry Klein</p>
                    </div>
                  </div>
                </div>
                <div class="badge badge-danger">3</div>
              </div>
            </div>
          </li> */}
          {/* <li class="nav-item sidebar-user-actions">
            <div class="sidebar-user-menu">
              <a  class="nav-link">
                <i class="mdi mdi-settings menu-icon"></i>
                <span class="menu-title">Settings</span>
              </a>
            </div>
          </li> */}
          {/* <li class="nav-item sidebar-user-actions">
            <div class="sidebar-user-menu">
              <a  class="nav-link">
                <i class="mdi mdi-speedometer menu-icon"></i>
                <span class="menu-title">Take Tour</span>
              </a>
            </div>
          </li> 
          <li class="nav-item sidebar-user-actions">
            <div class="sidebar-user-menu">
              <a  class="nav-link">
                <i class="mdi mdi-logout menu-icon"></i>
                <span class="menu-title">Log Out</span>
              </a>
            </div>
          </li>*/}
        </ul>
      </nav>
    </React.Fragment>
  );

};

export default withRouter(Sidebar);